import React from 'react';
import { graphql, Link } from 'gatsby';
import PageLayout from '@global/page-layout';
import PropTypes from 'prop-types';

export const data = graphql`
  query($tag: String) {
    tagsGroup: allContentfulBlogPost(
      limit: 2000
      sort: { fields: [publishDate], order: DESC }
      filter: { tags: { in: [$tag] } }
    ) {
      group(field: tags) {
        fieldValue
        totalCount
      }
      totalCount
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

const TagTemplate = ({ data: { tagsGroup: tag }, pageContext }) => {
  const { totalCount } = tag;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${pageContext.tag}"`;

  return (
    <PageLayout title="Tags" description={tagHeader}>
      <h1>{tagHeader}</h1>
      {tag.edges.map(({ node }) => {
        const { slug, title } = node;
        return (
          <li key={slug}>
            <Link to={`/${slug}/`}>{title}</Link>
          </li>
        );
      })}
    </PageLayout>
  );
};

TagTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  tag: PropTypes.string,
};
export default TagTemplate;
